import LibraryElementEnum from 'client/enums/LibraryElementEnum'

export default {
  TOOLBOX_SECTION: 'TOOLBOX_SECTION',
  TOOLBOX_LAYOUT_12: 'TOOLBOX_LAYOUT_12',
  TOOLBOX_LAYOUT_6_6: 'TOOLBOX_LAYOUT_6_6',
  TOOLBOX_LAYOUT_4_4_4: 'TOOLBOX_LAYOUT_4_4_4',
  TOOLBOX_LAYOUT_3_3_3_3: 'TOOLBOX_LAYOUT_3_3_3_3',
  TOOLBOX_POPUP: 'TOOLBOX_POPUP',
  TOOLBOX_POPUP_AGREEMENT: 'TOOLBOX_POPUP_AGREEMENT',
  TOOLBOX_POPUP_WEBINAR_REGISTRATION: 'TOOLBOX_POPUP_WEBINAR_REGISTRATION',
  TOOLBOX_INLINE: 'TOOLBOX_INLINE',
  TOOLBOX_CONTENT_BOX: 'TOOLBOX_CONTENT_BOX',
  TOOLBOX_TEXT: 'TOOLBOX_TEXT',
  TOOLBOX_TEXT_HEADLINE: 'TOOLBOX_TEXT_HEADLINE',
  TOOLBOX_BULLET_LIST: 'TOOLBOX_BULLET_LIST',
  TOOLBOX_AUDIO: 'TOOLBOX_AUDIO',
  TOOLBOX_BUTTON: 'TOOLBOX_BUTTON',
  TOOLBOX_BLOG_BUTTON: 'TOOLBOX_BLOG_BUTTON',
  TOOLBOX_OPEN_URL_BUTTON: 'TOOLBOX_OPEN_URL_BUTTON',
  TOOLBOX_PAYMENT_BUTTON: 'TOOLBOX_PAYMENT_BUTTON',
  TOOLBOX_FORM_INPUT: 'TOOLBOX_FORM_INPUT',
  TOOLBOX_START_TIMER: 'TOOLBOX_START_TIMER',
  TOOLBOX_WEBINAR_SESSION_DATE_TIME: 'TOOLBOX_WEBINAR_SESSION_DATE_TIME',
  TOOLBOX_WEBINAR_SESSION_CALL_TO_ACTION:
    'TOOLBOX_WEBINAR_SESSION_CALL_TO_ACTION',
  TOOLBOX_WEBINAR_SESSION_VIDEO: 'TOOLBOX_WEBINAR_SESSION_VIDEO',
  TOOLBOX_PAYMENT_METHOD: 'TOOLBOX_PAYMENT_METHOD',
  TOOLBOX_CUSTOMER_TYPE: 'TOOLBOX_CUSTOMER_TYPE',
  TOOLBOX_OFFER_PRICE: 'TOOLBOX_OFFER_PRICE',
  TOOLBOX_RAW_HTML: 'TOOLBOX_RAW_HTML',
  TOOLBOX_ORDER_BUMP: 'TOOLBOX_ORDER_BUMP',
  TOOLBOX_EXPLICIT_CONSENT: 'TOOLBOX_EXPLICIT_CONSENT',
  TOOLBOX_AGREEMENT: 'TOOLBOX_AGREEMENT',
  TOOLBOX_CUSTOM_LOGIN: 'TOOLBOX_CUSTOM_LOGIN',
  TOOLBOX_SALES_PHYSICAL_PRODUCT: 'TOOLBOX_SALES_PHYSICAL_PRODUCT',
  TOOLBOX_PAYMENT_PHYSICAL_PRODUCT: 'TOOLBOX_PAYMENT_PHYSICAL_PRODUCT',
  TOOLBOX_SALES_REDIRECTION_BUTTON: 'TOOLBOX_SALES_REDIRECTION_BUTTON',
  TOOLBOX_COUPON: 'TOOLBOX_COUPON',
  TOOLBOX_BLOG_POST_BODY: 'TOOLBOX_BLOG_POST_BODY',
  TOOLBOX_BLOG_POST_LISTING: 'TOOLBOX_BLOG_POST_LISTING',
  TOOLBOX_LATEST_BLOG_POSTS: 'TOOLBOX_LATEST_BLOG_POSTS',
  TOOLBOX_BLOG_CONTENT_PLACEHOLDER: 'TOOLBOX_BLOG_CONTENT_PLACEHOLDER',
  TOOLBOX_LANGUAGE_SWITCHER: 'TOOLBOX_LANGUAGE_SWITCHER', // inner usage only!
  TOOLBOX_TWO_STEP_PAYMENT_FORM: 'TOOLBOX_TWO_STEP_PAYMENT_FORM',
  TOOLBOX_TWITTER_TWEET_BUTTON: 'TOOLBOX_TWITTER_TWEET_BUTTON',
  TOOLBOX_SURVEY: 'TOOLBOX_SURVEY',
  TOOLBOX_CAROUSEL: 'TOOLBOX_CAROUSEL',
  [LibraryElementEnum.LibraryLanguageSwitcher]:
    LibraryElementEnum.LibraryLanguageSwitcher,
  [LibraryElementEnum.LibrarySurvey]: LibraryElementEnum.LibrarySurvey,
  [LibraryElementEnum.LibraryCarousel]: LibraryElementEnum.LibraryCarousel,
  [LibraryElementEnum.LibraryFaq]: LibraryElementEnum.LibraryFaq,
  [LibraryElementEnum.LibraryPricePlanCalculator]:
    LibraryElementEnum.LibraryPricePlanCalculator, // internal usage
  [LibraryElementEnum.LibraryWebinarRegistrationDate]:
    LibraryElementEnum.LibraryWebinarRegistrationDate,
  [LibraryElementEnum.LibraryOrderSummary]:
    LibraryElementEnum.LibraryOrderSummary,
  [LibraryElementEnum.LibraryText]: LibraryElementEnum.LibraryText,
  [LibraryElementEnum.LibraryHeadline]: LibraryElementEnum.LibraryHeadline,
  [LibraryElementEnum.LibraryCustomLogin]:
    LibraryElementEnum.LibraryCustomLogin,
  [LibraryElementEnum.LibraryBlogPostImage]:
    LibraryElementEnum.LibraryBlogPostImage,
  [LibraryElementEnum.LibraryContentTable]:
    LibraryElementEnum.LibraryContentTable,
  [LibraryElementEnum.LibraryBreadcrumbs]:
    LibraryElementEnum.LibraryBreadcrumbs,
  [LibraryElementEnum.LibraryContactUs]: LibraryElementEnum.LibraryContactUs,
  [LibraryElementEnum.LibraryBlogPostDate]:
    LibraryElementEnum.LibraryBlogPostDate,
  [LibraryElementEnum.LibraryBlogPostTitle]:
    LibraryElementEnum.LibraryBlogPostTitle,
  [LibraryElementEnum.LibraryUpsellAgreeButton]:
    LibraryElementEnum.LibraryUpsellAgreeButton,
  [LibraryElementEnum.LibraryUpsellDisagreeButton]:
    LibraryElementEnum.LibraryUpsellDisagreeButton,
  [LibraryElementEnum.LibraryMenu]: LibraryElementEnum.LibraryMenu,
  [LibraryElementEnum.LibraryBlogPostCategories]:
    LibraryElementEnum.LibraryBlogPostCategories,
  [LibraryElementEnum.LibraryImage]: LibraryElementEnum.LibraryImage,
  [LibraryElementEnum.LibraryBlogContentPlaceholder]:
    LibraryElementEnum.LibraryBlogContentPlaceholder,
  [LibraryElementEnum.LibraryBlogPostBody]:
    LibraryElementEnum.LibraryBlogPostBody,
  [LibraryElementEnum.LibraryBlogPostListing]:
    LibraryElementEnum.LibraryBlogPostListing,
  [LibraryElementEnum.LibraryBlogLatestPosts]:
    LibraryElementEnum.LibraryBlogLatestPosts,
  [LibraryElementEnum.LibraryVideo]: LibraryElementEnum.LibraryVideo,
  [LibraryElementEnum.LibraryHorizontalLine]:
    LibraryElementEnum.LibraryHorizontalLine,
  [LibraryElementEnum.LibraryBlogCategoryTitle]:
    LibraryElementEnum.LibraryBlogCategoryTitle,
  [LibraryElementEnum.LibraryBlogCategoryDescription]:
    LibraryElementEnum.LibraryBlogCategoryDescription,
  [LibraryElementEnum.LibraryWebinarSessionLink]:
    LibraryElementEnum.LibraryWebinarSessionLink,
  [LibraryElementEnum.LibraryWebinarSessionVideo]:
    LibraryElementEnum.LibraryWebinarSessionVideo,
  [LibraryElementEnum.LibraryWebinarSessionDateTime]:
    LibraryElementEnum.LibraryWebinarSessionDateTime,
  [LibraryElementEnum.LibraryLatestBlogPostsCarousel]:
    LibraryElementEnum.LibraryLatestBlogPostsCarousel,
  [LibraryElementEnum.LibraryAudio]: LibraryElementEnum.LibraryAudio,
  [LibraryElementEnum.LibraryOfferPrice]: LibraryElementEnum.LibraryOfferPrice,
  [LibraryElementEnum.LibraryProduct]: LibraryElementEnum.LibraryProduct,
  [LibraryElementEnum.LibrarySalesRedirectionButton]:
    LibraryElementEnum.LibrarySalesRedirectionButton,
  [LibraryElementEnum.LibraryFacebookComments]:
    LibraryElementEnum.LibraryFacebookComments,
  [LibraryElementEnum.LibraryOptInRecaptcha]:
    LibraryElementEnum.LibraryOptInRecaptcha,
  [LibraryElementEnum.LibraryTwitterTweetButton]:
    LibraryElementEnum.LibraryTwitterTweetButton,
  [LibraryElementEnum.LibraryCountdown]: LibraryElementEnum.LibraryCountdown,
  [LibraryElementEnum.LibraryCustomerType]:
    LibraryElementEnum.LibraryCustomerType,
  [LibraryElementEnum.LibraryRow]: LibraryElementEnum.LibraryRow,
  [LibraryElementEnum.LibraryColumn6]: LibraryElementEnum.LibraryColumn6,
  [LibraryElementEnum.LibraryColumn4]: LibraryElementEnum.LibraryColumn4,
  [LibraryElementEnum.LibraryColumn3]: LibraryElementEnum.LibraryColumn3,
  [LibraryElementEnum.LibraryContentBox]: LibraryElementEnum.LibraryContentBox,
  [LibraryElementEnum.LibraryPaymentMethod]:
    LibraryElementEnum.LibraryPaymentMethod,
  [LibraryElementEnum.LibrarySection]: LibraryElementEnum.LibrarySection,
  [LibraryElementEnum.LibraryRawHTML]: LibraryElementEnum.LibraryRawHTML,
  [LibraryElementEnum.LibraryCheckbox]: LibraryElementEnum.LibraryCheckbox,
  [LibraryElementEnum.LibraryShippingFees]:
    LibraryElementEnum.LibraryShippingFees,
  [LibraryElementEnum.LibraryBulletList]: LibraryElementEnum.LibraryBulletList,
  [LibraryElementEnum.LibraryPaymentButton]:
    LibraryElementEnum.LibraryPaymentButton,
}
