import React from 'react'
import MenuInterface from 'common/components/entities/Menu/types/MenuInterface'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import { SectionInterface } from 'common/components/entities/Section/types/SectionInterface'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { AudioInterface } from 'common/types/entities/AudioInterface'
import { BlogPostImageInterface } from 'common/types/entities/BlogPostImageInterface'
import { BulletListInterface } from 'common/types/entities/BulletListInterface'
import CarouselInterface from 'common/types/entities/CarouselInterface'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'
import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'
import { ContentTableInterface } from 'common/types/entities/ContentTableInterface'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { CustomLoginForgotPasswordInterface } from 'common/types/entities/CustomLoginForgotPasswordInterface'
import CustomLoginInterface from 'common/types/entities/CustomLoginInterface'
import { CustomerTypeInterface } from 'common/types/entities/CustomerTypeInteface'
import EntityInterface, {
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { FacebookCommentsInterface } from 'common/types/entities/FacebookCommentsInterface'
import FaqInterface, {
  FaqItemInterface,
} from 'common/types/entities/FaqInterface'
import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import { HorizontalLineInterface } from 'common/types/entities/HorizontalLineInterface'
import { ImageInterface } from 'common/types/entities/ImageInterface'
import { LanguageSwitcherInterface } from 'common/types/entities/LanguageSwitcherInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import PricePlanCalculatorInterface from 'common/types/entities/PricePlanCalculatorInterface'
import ProductEntityInterface from 'common/types/entities/ProductInterface'
import { RawHtmlInterface } from 'common/types/entities/RawHtmlInterface'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import { ShippingFeesInterface } from 'common/types/entities/ShippingFeesInterface'
import { SurveyInterface } from 'common/types/entities/SurveyInterface'
import { TextAreaInterface } from 'common/types/entities/TextAreaInterface'
import { TextInterface } from 'common/types/entities/TextInterface'
import { TwitterTweetButtonInterface } from 'common/types/entities/TwitterTweetButtonInterface'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import CheckboxSettings from 'client/components/core/Sidebar/components/Settings/entities/CheckboxSettings'
import CountdownSettings from 'client/components/core/Sidebar/components/Settings/entities/Countdown'
import FacebookCommentsSettings from 'client/components/core/Sidebar/components/Settings/entities/FacebookCommentsSettings'
import RowSettings from 'client/components/core/Sidebar/components/Settings/entities/RowSettings'
import SectionSettings from 'client/components/core/Sidebar/components/Settings/entities/SectionSettings'
import ShippingFeesSettings from 'client/components/core/Sidebar/components/Settings/entities/ShippingFeesSettings'
import CustomerTypeSettingsNew from 'client/pages/offer/settings/CustomerTypeSettings'
import PaymentButtonSettingsNew from 'client/pages/offer/settings/PaymentButtonSettingsNew'
import ProductSettings from 'client/pages/offer/settings/ProductSettings'
import AudioSettingsNew from './AudioSettingsNew'
import BlogPostImageSettings from './BlogPostImageSettings'
import CarouselSettings from './CarouselSettings'
import ContentBoxSettings from './ContentBoxSettings'
import ContentTableSettings from './ContentTableSettings'
import CustomLoginSettings from './CustomLogin'
import ForgotPasswordSettings from './CustomLogin/components/ForgotPasswordSettings'
import FaqSettings from './FaqSettings'
import FaqItemSettings from './FawItemSettings'
import HeadlineSettings from './HeadlineSettings'
import ImageSettingsNew from './ImageSettingsNew'
import MenuSettings from './MenuSettings'
import NewBulletListSettings from './NewBulletListSettings'
import NewHorizontalLineSettings from './NewHorizontalLineSettings'
import NewLanguageSwitcherSettings from './NewLanguageSwitcherSettings'
import NewRawHtmlSettings from './NewRawHtmlSettings'
import OfferThankYouOrderSummarySettingsNew from './OfferThankYouOrderSummarySettingsNew'
import PricePlanCalculatorSettings from './PricePlanCalculatorSettings'
import RecaptchaSettings from './RecaptchaSettings'
import SurveySettings from './SurveySettings/SurveySettings'
import TextAreaSettings from './TextAreaSettings'
import TextSettingsNew from './TextSettingsNew'
import TwitterTweetButtonSettings from './TwitterTweetButtonSettings'
import VideoSettingsNew from './VideoSettingsNew'

export type SettingsProps<T> = {
  entity: T
}

export default function createEntitySettings(
  entity: EntityInterface | EntityInnerItemInterface,
) {
  switch (entity.type) {
    case EntityTypeEnum.Image:
      return <ImageSettingsNew entity={entity as ImageInterface} />
    case EntityTypeEnum.LanguageSwitcher:
      return (
        <NewLanguageSwitcherSettings
          entity={entity as LanguageSwitcherInterface}
        />
      )
    case EntityTypeEnum.Checkbox:
      return <CheckboxSettings entity={entity as CheckboxInterface} />
    case EntityTypeEnum.Text:
      return <TextSettingsNew entity={entity as TextInterface} />
    case EntityTypeEnum.RawHtml:
      return <NewRawHtmlSettings entity={entity as RawHtmlInterface} />
    case EntityTypeEnum.Headline:
      return <HeadlineSettings entity={entity as HeadlineInterface} />
    case EntityTypeEnum.Carousel:
    case structureTypes.CAROUSEL:
      return <CarouselSettings entity={entity as CarouselInterface} />
    case EntityTypeEnum.Faq:
      return <FaqSettings entity={entity as FaqInterface} />
    case EntityTypeEnum.FaqItem:
      return <FaqItemSettings entity={entity as FaqItemInterface} />
    case EntityTypeEnum.PricePlanCalculator:
      return (
        <PricePlanCalculatorSettings
          entity={entity as PricePlanCalculatorInterface}
        />
      )
    case EntityTypeEnum.CustomLogin:
      return <CustomLoginSettings entity={entity as CustomLoginInterface} />
    case EntityTypeEnum.BlogPostImage:
      return <BlogPostImageSettings entity={entity as BlogPostImageInterface} />
    case EntityTypeEnum.ContentTable:
      return <ContentTableSettings entity={entity as ContentTableInterface} />
    case EntityTypeEnum.TextArea:
      return <TextAreaSettings entity={entity as TextAreaInterface} />
    case EntityTypeEnum.TwitterTweetButton:
      return (
        <TwitterTweetButtonSettings
          entity={entity as TwitterTweetButtonInterface}
        />
      )
    case EntityTypeEnum.OrderSummary:
      return (
        <OfferThankYouOrderSummarySettingsNew
          entity={entity as EntityInterface}
        />
      )
    case EntityTypeEnum.Menu:
      return <MenuSettings entity={entity as MenuInterface} />
    case EntityTypeEnum.Survey:
      return <SurveySettings entity={entity as SurveyInterface} />
    case EntityTypeEnum.Video:
      return <VideoSettingsNew entity={entity as VideoInterface} />
    case EntityTypeEnum.HorizontalLine:
      return (
        <NewHorizontalLineSettings entity={entity as HorizontalLineInterface} />
      )
    case EntityTypeEnum.Audio:
      return <AudioSettingsNew entity={entity as AudioInterface} />
    case EntityTypeEnum.Product:
      return <ProductSettings entity={entity as ProductEntityInterface} />
    case EntityTypeEnum.FacebookComments:
      return (
        <FacebookCommentsSettings
          entity={entity as FacebookCommentsInterface}
        />
      )
    case EntityTypeEnum.Countdown:
      return <CountdownSettings entity={entity as CountdownInterface} />
    case EntityTypeEnum.Recaptcha:
    case EntityTypeEnum.OptInRecaptcha:
    case EntityTypeEnum.ContactUsRecaptcha:
      return <RecaptchaSettings entity={entity as RecaptchaInterface} />
    case EntityTypeEnum.CustomLoginForgotPassword:
      return (
        <ForgotPasswordSettings
          entity={entity as CustomLoginForgotPasswordInterface}
        />
      )
    case EntityTypeEnum.CustomerType:
      return (
        <CustomerTypeSettingsNew entity={entity as CustomerTypeInterface} />
      )
    case EntityTypeEnum.ContentBox:
      return <ContentBoxSettings entity={entity as ContentBoxInterface} />
    case EntityTypeEnum.Row:
      return <RowSettings entity={entity as RowInterface} />
    case EntityTypeEnum.Section:
      return <SectionSettings entity={entity as SectionInterface} />
    case EntityTypeEnum.ShippingFees:
      return <ShippingFeesSettings entity={entity as ShippingFeesInterface} />
    case EntityTypeEnum.BulletList:
      return <NewBulletListSettings entity={entity as BulletListInterface} />
    case EntityTypeEnum.PaymentButton:
      return (
        <PaymentButtonSettingsNew entity={entity as PaymentButtonInterface} />
      )
    default:
      return <div />
  }
}
