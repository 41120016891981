import RecaptchaIconRenderer from 'common/components/entities/Recaptcha/ui/RecaptchaIconUi'
import TwitterButtonIconUi from 'common/components/entities/TwitterTweetButton/ui/TwitterButtonIcon'
import toolBoxTypes from 'common/constants/toolBoxTypes'
import LibraryElementEnum from 'client/enums/LibraryElementEnum'

export default {
  [LibraryElementEnum.LibrarySection]: {
    className: 'column column-row',
    title: 'toolbox.entities.section',
  },
  [LibraryElementEnum.LibraryRow]: {
    className: 'column column-12',
    title: 'toolbox.entities.column_12',
  },
  [LibraryElementEnum.LibraryColumn6]: {
    className: 'column column-6-6',
    title: 'toolbox.entities.column_6_6',
  },
  [LibraryElementEnum.LibraryColumn4]: {
    className: 'column column-4-4-4',
    title: 'toolbox.entities.column_4_4_4',
  },
  [LibraryElementEnum.LibraryColumn3]: {
    className: 'column column-3-3-3-3',
    title: 'toolbox.entities.column_3_3_3_3',
  },
  [LibraryElementEnum.LibraryContentBox]: {
    className: 'fa fa-window-maximize',
    title: 'toolbox.entities.content_box',
  },
  [LibraryElementEnum.LibraryHeadline]: {
    className: 'fas fa-heading',
    title: 'toolbox.entities.text_headline',
  },
  [LibraryElementEnum.LibraryBulletList]: {
    className: 'fas fa-list-ul',
    title: 'toolbox.entities.bullet_list',
  },
  [LibraryElementEnum.LibraryVideo]: {
    className: 'fas fa-video',
    title: 'toolbox.entities.video',
  },
  [LibraryElementEnum.LibraryImage]: {
    className: 'far fa-image',
    title: 'toolbox.entities.image',
  },
  [LibraryElementEnum.LibraryAudio]: {
    className: 'fas fa-volume-up',
    title: 'toolbox.entities.audio',
  },
  [toolBoxTypes.TOOLBOX_BUTTON]: {
    className: 'far fa-hand-point-up',
    title: 'toolbox.entities.button',
  },
  [toolBoxTypes.TOOLBOX_BLOG_BUTTON]: {
    className: 'far fa-hand-point-up',
    title: 'toolbox.entities.button',
  },
  [toolBoxTypes.TOOLBOX_OPEN_URL_BUTTON]: {
    className: 'far fa-hand-point-up',
    title: 'toolbox.entities.button',
  },
  [toolBoxTypes.TOOLBOX_PAYMENT_BUTTON]: {
    className: 'far fa-hand-point-up',
    title: 'toolbox.entities.payment_button',
  },
  [LibraryElementEnum.LibraryPaymentButton]: {
    className: 'far fa-hand-point-up',
    title: 'toolbox.entities.payment_button',
  },
  [LibraryElementEnum.LibraryUpsellAgreeButton]: {
    className: 'far fa-thumbs-up',
    title: 'toolbox.entities.payment_button_agree',
  },
  [LibraryElementEnum.LibraryUpsellDisagreeButton]: {
    className: 'far fa-thumbs-down',
    title: 'toolbox.entities.payment_button_disagree',
  },
  [toolBoxTypes.TOOLBOX_FORM_INPUT]: {
    className: 'far fa-edit',
    title: 'toolbox.entities.form_input',
  },
  [toolBoxTypes.TOOLBOX_START_TIMER]: {
    className: 'far fa-clock',
    title: 'toolbox.entities.start_timer',
  },
  [LibraryElementEnum.LibraryCountdown]: {
    className: 'far fa-clock',
    title: 'toolbox.entities.countdown',
  },
  [LibraryElementEnum.LibraryWebinarRegistrationDate]: {
    className: 'fas fa-calendar-alt',
    title: 'toolbox.entities.webinar_registration_date',
  },
  [LibraryElementEnum.LibraryOrderSummary]: {
    className: 'fas fa-shopping-cart',
    title: 'toolbox.entities.order_summary',
  },
  [LibraryElementEnum.LibraryWebinarSessionDateTime]: {
    className: 'far fa-clock',
    title: 'toolbox.entities.webinar_session_date_time',
  },
  [LibraryElementEnum.LibraryWebinarSessionLink]: {
    className: 'fas fa-link',
    title: 'toolbox.entities.webinar_session_link',
  },
  [LibraryElementEnum.LibraryCustomerType]: {
    className: 'far fa-user',
    title: 'toolbox.entities.customer_type',
  },
  [toolBoxTypes.TOOLBOX_OFFER_PRICE]: {
    className: 'fas fa-euro-sign',
    title: 'toolbox.entities.offer_price',
  },
  [LibraryElementEnum.LibraryOfferPrice]: {
    className: 'fas fa-dollar-sign',
    title: 'toolbox.entities.offer_price',
  },
  [LibraryElementEnum.LibraryRawHTML]: {
    className: 'fas fa-code',
    title: 'toolbox.entities.raw_html',
  },
  [toolBoxTypes.TOOLBOX_ORDER_BUMP]: {
    className: 'far fa-money-bill-alt',
    title: 'toolbox.entities.order_bump',
  },
  [LibraryElementEnum.LibraryCheckbox]: {
    className: 'far fa-check-square',
    title: 'toolbox.entities.checkbox',
  },
  [toolBoxTypes.TOOLBOX_AGREEMENT]: {
    className: 'far fa-handshake',
    title: 'toolbox.entities.agreement',
  },
  [toolBoxTypes.TOOLBOX_CUSTOM_LOGIN]: {
    className: 'fas fa-sign-in-alt',
    title: 'toolbox.entities.member_access',
  },
  [LibraryElementEnum.LibraryCustomLogin]: {
    className: 'fas fa-sign-in-alt',
    title: 'toolbox.entities.member_access',
  },
  [toolBoxTypes.TOOLBOX_SALES_PHYSICAL_PRODUCT]: {
    className: 'fa fa-archive',
    title: 'toolbox.entities.physical_product',
  },
  [LibraryElementEnum.LibraryProduct]: {
    className: 'fa fa-archive',
    title: 'toolbox.entities.physical_product',
  },
  [toolBoxTypes.TOOLBOX_PAYMENT_PHYSICAL_PRODUCT]: {
    className: 'fa fa-archive',
    title: 'toolbox.entities.physical_product',
  },
  [LibraryElementEnum.LibrarySalesRedirectionButton]: {
    className: 'fas fa-link',
    title: 'toolbox.entities.sales_redirection_button',
  },
  [toolBoxTypes.TOOLBOX_COUPON]: {
    className: 'fas fa-tags',
    title: 'toolbox.entities.coupon',
  },
  [LibraryElementEnum.LibraryFacebookComments]: {
    className: 'far fa-comments',
    title: 'toolbox.entities.fb_comments',
  },
  [LibraryElementEnum.LibraryWebinarCallToAction]: {
    className: 'far fa-hand-point-up',
    title: 'toolbox.entities.webinar_session_call_to_action',
  },
  [LibraryElementEnum.LibraryWebinarSessionVideo]: {
    className: 'fas fa-video',
    title: 'toolbox.entities.webinar_session_video',
  },
  [LibraryElementEnum.LibraryPaymentMethod]: {
    className: 'far fa-credit-card',
    title: 'toolbox.entities.payment_method',
  },
  [LibraryElementEnum.LibraryBlogPostBody]: {
    className: 'fab fa-ioxhost',
    title: 'toolbox.entities.blog_post_body',
  },
  [LibraryElementEnum.LibraryBlogPostTitle]: {
    className: 'fas fa-heading',
    title: 'toolbox.entities.blog_post_title',
  },

  [LibraryElementEnum.LibraryBlogPostDate]: {
    className: 'fas fa-heading',
    title: 'toolbox.entities.blog_post_date',
  },
  [LibraryElementEnum.LibraryBlogPostCategories]: {
    className: 'fas fa-tags',
    title: 'toolbox.entities.blog_post_categories',
  },
  [LibraryElementEnum.LibraryBlogPostListing]: {
    className: 'column posts-library-icon',
    title: 'toolbox.entities.blog_post_listing',
  },
  [LibraryElementEnum.LibraryBlogLatestPosts]: {
    className: 'latest-posts-library-icon',
    title: 'toolbox.entities.latest_blog_posts',
  },
  [LibraryElementEnum.LibraryLatestBlogPostsCarousel]: {
    className: 'far fa-images',
    title: 'toolbox.entities.latest_blog_posts_carousel',
  },
  [LibraryElementEnum.LibraryHorizontalLine]: {
    className: 'toolbox-horizontal-line',
    title: 'toolbox.entities.horizontal_line',
  },
  [LibraryElementEnum.LibraryBlogContentPlaceholder]: {
    className: 'far fa-newspaper',
    title: 'toolbox.entities.blog_content',
  },
  [LibraryElementEnum.LibraryMenu]: {
    className: 'fas fa-bars',
    title: 'toolbox.entities.menu',
  },
  [LibraryElementEnum.LibraryLanguageSwitcher]: {
    className: 'fas fa-globe',
    title: 'toolbox.entities.language_switcher',
  },
  [toolBoxTypes.TOOLBOX_TWO_STEP_PAYMENT_FORM]: {
    className: 'fas fa-cart-plus',
    title: 'toolbox.entities.two_step_payment_form',
  },
  [toolBoxTypes.TOOLBOX_TWITTER_TWEET_BUTTON]: {
    iconRenderer: TwitterButtonIconUi,
    title: 'toolbox.entities.twitter_tweet_button',
  },
  [LibraryElementEnum.LibraryTwitterTweetButton]: {
    className: 'fab fa-twitter',
    title: 'toolbox.entities.twitter_tweet_button',
  },
  [LibraryElementEnum.LibraryCarousel]: {
    className: 'far fa-images',
    title: 'toolbox.entities.carousel',
  },
  [LibraryElementEnum.LibraryFaq]: {
    className: 'far fa-question-circle',
    title: 'toolbox.entities.faq',
  },
  [LibraryElementEnum.LibraryPricePlanCalculator]: {
    className: 'fas fa-calculator',
    title: 'toolbox.entities.price_plan_calculator',
  },
  [LibraryElementEnum.LibraryText]: {
    className: 'fas fa-font',
    title: 'toolbox.entities.text',
  },
  [LibraryElementEnum.LibraryBlogPostImage]: {
    className: 'far fa-image',
    title: 'toolbox.entities.blog_post_image',
  },
  [LibraryElementEnum.LibraryContentTable]: {
    className: 'fal fa-receipt',
    title: 'toolbox.entities.content_table',
  },
  [LibraryElementEnum.LibraryContactUs]: {
    className: 'fal fa-envelope-open-text',
    title: 'toolbox.entities.contact_us',
  },
  [LibraryElementEnum.LibraryBreadcrumbs]: {
    className: 'fad fa-bread-slice',
    title: 'toolbox.entities.breadcrumbs',
  },
  [LibraryElementEnum.LibrarySurvey]: {
    className: 'fas fa-poll',
    title: 'toolbox.entities.survey',
  },
  [LibraryElementEnum.LibraryBlogCategoryTitle]: {
    className: 'fas fa-heading',
    title: 'toolbox.entities.blog_category_title',
  },
  [LibraryElementEnum.LibraryBlogCategoryDescription]: {
    className: 'fas fa-paragraph',
    title: 'toolbox.entities.blog_category_description',
  },
  [LibraryElementEnum.LibraryOptInRecaptcha]: {
    title: 'toolbox.entities.recaptcha',
    iconRenderer: RecaptchaIconRenderer,
  },
  [LibraryElementEnum.LibraryShippingFees]: {
    className: 'far fa-truck-fast',
    title: 'toolbox.entities.shipping_fees',
  },
}
