// @ts-nocheck
import React from 'react'
import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import MenuInterface from 'common/components/entities/Menu/types/MenuInterface'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import { SectionInterface } from 'common/components/entities/Section/types/SectionInterface'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { AudioInterface } from 'common/types/entities/AudioInterface'
import { BlogContentPlaceholderInterface } from 'common/types/entities/BlogContentPlaceholderInterface'
import CarouselInterface from 'common/types/entities/CarouselInterface'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'
import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'
import { ContentTableInterface } from 'common/types/entities/ContentTableInterface'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { CustomLoginForgotPasswordInterface } from 'common/types/entities/CustomLoginForgotPasswordInterface'
import { CustomerTypeInterface } from 'common/types/entities/CustomerTypeInteface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { FacebookCommentsInterface } from 'common/types/entities/FacebookCommentsInterface'
import FaqInterface from 'common/types/entities/FaqInterface'
import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import { HorizontalLineInterface } from 'common/types/entities/HorizontalLineInterface'
import { ImageInterface } from 'common/types/entities/ImageInterface'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { PaymentMethodInterface } from 'common/types/entities/PaymentMethodInterface'
import PricePlanCalculatorInterface from 'common/types/entities/PricePlanCalculatorInterface'
import ProductEntityInterface from 'common/types/entities/ProductInterface'
import { ShippingFeesInterface } from 'common/types/entities/ShippingFeesInterface'
import { SurveyInterface } from 'common/types/entities/SurveyInterface'
import { TextInterface } from 'common/types/entities/TextInterface'
import { TwitterTweetButtonInterface } from 'common/types/entities/TwitterTweetButtonInterface'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import Agreement from 'client/components/entities/Agreement'
import Audio from 'client/components/entities/Audio'
import AudioNew from 'client/components/entities/Audio/AudioNew'
import BlogContentPlaceholder from 'client/components/entities/BlogContentPlaceholder'
import BlogPostContentPlaceholder from 'client/components/entities/BlogPostContentPlaceholder'
import Body from 'client/components/entities/Body'
import BulletList from 'client/components/entities/BulletList'
import Button from 'client/components/entities/Button'
import Checkbox from 'client/components/entities/Checkbox'
import Column from 'client/components/entities/Column'
import ColumnOld from 'client/components/entities/Column/ColumnOld'
import ContentBox from 'client/components/entities/ContentBox'
import ContentBoxOld from 'client/components/entities/ContentBox/ContentBoxOld'
import Countdown from 'client/components/entities/Countdown'
import CountdownOld from 'client/components/entities/Countdown/CountdownOld'
import Coupon from 'client/components/entities/Coupon'
import CustomerType from 'client/components/entities/CustomerType'
import ExplicitConsent from 'client/components/entities/ExplicitConsent'
import FacebookComments from 'client/components/entities/FacebookComments'
import Field from 'client/components/entities/Field'
import Headline from 'client/components/entities/Headline'
import Image from 'client/components/entities/Image'
import ImageNew from 'client/components/entities/Image/Image'
import Inline from 'client/components/entities/Inline'
import LanguageSwitcher from 'client/components/entities/LanguageSwitcher'
import Menu from 'client/components/entities/Menu/Menu'
import NewBulletList from 'client/components/entities/NewBulletList'
import NewHorizontalLine from 'client/components/entities/NewHorizontalLine'
import OfferPrice from 'client/components/entities/OfferPrice'
import OfferThankYouOrderSummaryNew from 'client/components/entities/OfferThankYouOrderSummaryNew'
import OrderBump from 'client/components/entities/OrderBump'
import PaymentButtonNew from 'client/components/entities/PaymentButton/PaymentButtonNew'
import PaymentMethods from 'client/components/entities/PaymentMethods'
import Popup from 'client/components/entities/Popup'
import RawHtml from 'client/components/entities/RawHtml'
import Recaptcha, {
  RecaptchaInterface,
} from 'client/components/entities/Recaptcha'
import RemotePopup from 'client/components/entities/RemotePopup'
import Row from 'client/components/entities/Row'
import ReadOnlyRowOld from 'client/components/entities/Row/ReadOnlyRowOld'
import RowOld from 'client/components/entities/Row/RowOld'
import ReadOnlyRow from 'client/components/entities/Row/components/ReadOnlyRow'
import Section from 'client/components/entities/Section'
import SectionOld from 'client/components/entities/Section/SectionOld'
import ShippingFees from 'client/components/entities/ShippingFees'
import StartTimer from 'client/components/entities/StartTimer'
import Text from 'client/components/entities/Text'
import TextNew from 'client/components/entities/Text/Text'
import TwitterTweetButton from 'client/components/entities/TwitterTweetButton'
import TwitterTweetButtonOld from 'client/components/entities/TwitterTweetButtonOld'
import TwoStepPaymentForm from 'client/components/entities/TwoStepPaymentForm/TwoStepPaymentForm'
import Video from 'client/components/entities/Video'
import WebinarSessionCallToAction from 'client/components/entities/WebinarSessionCallToAction'
import WebinarSessionDateTime from 'client/components/entities/WebinarSessionDateTime'
import OfferPriceNew from 'client/pages/offer/entities/OfferPrice'
import Product from 'client/pages/offer/entities/Product'
import { LanguageSwitcherInterface } from '../../common/types/entities/LanguageSwitcherInterface'
import { RawHtmlInterface } from '../../common/types/entities/RawHtmlInterface'
import Carousel from '../components/entities/Carousel'
import ContentTable from '../components/entities/ContentTable'
import Faq from '../components/entities/Faq'
import NewLanguageSwitcher from '../components/entities/LanguageSwitcher/NewLanguageSwitcher'
import PricePlanCalculator from '../components/entities/PricePlanCalculator'
import NewRawHtml from '../components/entities/RawHtml/NewRawHtml'
import Survey from '../components/entities/Survey'
import SurveyNew from '../components/entities/Survey/Survey'
import VideoNew from '../components/entities/VideoNew'
import BlogContentPlaceholderNew from './blog/entities/BlogContentPlaceholderNew'
import { ForgotPassword } from './custom-login/entities/ForgotPassword'
import CustomerTypeNew from './offer/entities/CustomerType'
import PaymentMethod from './offer/entities/PaymentMethod'

export type AdditionalProps = {
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

export function createEmptyEntityElement() {
  return <div />
}

export default function createCommonEntityElement(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  const { type, id } = entity
  switch (type) {
    case structureTypes.BODY:
    case structureTypes.BLOG_POST_BODY:
    case structureTypes.BLOG_PAGE_BODY:
    case structureTypes.BLOG_POST_LAYOUT_BODY: {
      return (
        <Body
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    }
    case structureTypes.SECTION: {
      return (
        <SectionOld
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    }
    case EntityTypeEnum.Section:
      return (
        <Section
          entity={entity as SectionInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.ROW: {
      if (entity.isReadOnly) {
        return (
          <ReadOnlyRowOld
            entity={entity as OldEntityInterface}
            key={`entity-${id}`}
          />
        )
      }

      return (
        <RowOld
          entity={entity as OldEntityInterface}
          childIds={entity.childIds}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    }
    case EntityTypeEnum.Row: {
      if (entity.isReadOnly) {
        return (
          <ReadOnlyRow entity={entity as RowInterface} key={`entity-${id}`} />
        )
      }
      return (
        <Row
          entity={entity as RowInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    }
    case structureTypes.COLUMN:
      return (
        <ColumnOld
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Column:
      return (
        <Column
          entity={entity as ColumnInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Headline:
      return (
        <Headline
          entity={entity as HeadlineInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.TEXT:
      return (
        <Text
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.BULLET_LIST:
      return (
        <BulletList
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.BulletList:
      return (
        <NewBulletList
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.IMAGE:
      return (
        <Image
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Image:
      return (
        <ImageNew
          entity={entity as ImageInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.BUTTON:
    case structureTypes.SALES_REDIRECTION_BUTTON:
    case structureTypes.PAYMENT_BUTTON:
      return (
        <Button
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.FORM_INPUT:
      return (
        <Field
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.WEBINAR_SESSION_DATE_TIME:
      return (
        <WebinarSessionDateTime
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.WEBINAR_SESSION_CALL_TO_ACTION:
      return (
        <WebinarSessionCallToAction
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.POPUP:
      return (
        <Popup
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.REMOTE_POPUP:
      return (
        <RemotePopup
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.INLINE:
      return (
        <Inline
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.WEBINAR_SESSION_VIDEO:
      return (
        <Video
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Video:
      return (
        <VideoNew
          entity={entity as VideoInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.AUDIO:
      return (
        <Audio
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.CONTENT_BOX:
      return (
        <ContentBoxOld
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.PAYMENT_METHOD:
      return (
        <PaymentMethods
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.CUSTOMER_TYPE:
      return (
        <CustomerType
          entity={entity}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.OFFER_PRICE:
      return (
        <OfferPrice
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.OfferPrice:
      return (
        <OfferPriceNew
          entity={entity as OfferPriceInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Product:
      return (
        <Product
          entity={entity as ProductEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.RAW_HTML:
      return (
        <RawHtml
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.RawHtml:
      return (
        <NewRawHtml
          entity={entity as RawHtmlInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.ORDER_BUMP:
      return (
        <OrderBump
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.EXPLICIT_CONSENT:
      return (
        <ExplicitConsent
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Checkbox:
      return (
        <Checkbox
          entity={entity as CheckboxInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.START_TIMER:
      return (
        <StartTimer
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.AGREEMENT:
      return (
        <Agreement
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.OrderSummary:
      return (
        <OfferThankYouOrderSummaryNew
          entity={entity as EntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.COUNTDOWN:
      return (
        <CountdownOld
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.COUPON:
      return (
        <Coupon
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.BLOG_POST_CONTENT_PLACEHOLDER:
      return (
        <BlogPostContentPlaceholder
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.BLOG_CONTENT_PLACEHOLDER:
      return (
        <BlogContentPlaceholder
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.BlogContentPlaceholder:
      return (
        <BlogContentPlaceholderNew
          entity={entity as BlogContentPlaceholderInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Menu:
      return (
        <Menu
          entity={entity as MenuInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.LANGUAGE_SWITCHER:
      return (
        <LanguageSwitcher
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.LanguageSwitcher:
      return (
        <NewLanguageSwitcher
          entity={entity as LanguageSwitcherInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.TWO_STEP_PAYMENT_FORM:
      return (
        <TwoStepPaymentForm
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.TWITTER_TWEET_BUTTON:
      return (
        <TwitterTweetButtonOld
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.TwitterTweetButton:
      return (
        <TwitterTweetButton
          entity={entity as TwitterTweetButtonInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case structureTypes.SURVEY:
      return (
        <Survey
          entity={entity as OldEntityInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Carousel:
    case structureTypes.CAROUSEL:
      return (
        <Carousel
          entity={entity as CarouselInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Faq:
      return (
        <Faq
          entity={entity as FaqInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.PricePlanCalculator:
      return (
        <PricePlanCalculator
          entity={entity as PricePlanCalculatorInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Text:
      return (
        <TextNew
          entity={entity as TextInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.ContentTable:
      return (
        <ContentTable
          entity={entity as ContentTableInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Survey:
      return (
        <SurveyNew entity={entity as SurveyInterface} {...additionalProps} />
      )
    case EntityTypeEnum.HorizontalLine:
      return (
        <NewHorizontalLine
          entity={entity as HorizontalLineInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Audio:
      return (
        <AudioNew
          entity={entity as AudioInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.FacebookComments: {
      return (
        <FacebookComments
          entity={entity as FacebookCommentsInterface}
          {...additionalProps}
        />
      )
    }
    case EntityTypeEnum.OptInRecaptcha: {
      return (
        <Recaptcha entity={entity as RecaptchaInterface} {...additionalProps} />
      )
    }
    case EntityTypeEnum.Countdown: {
      return (
        <Countdown entity={entity as CountdownInterface} {...additionalProps} />
      )
    }
    case EntityTypeEnum.CustomLoginForgotPassword: {
      return (
        <ForgotPassword
          entity={entity as CustomLoginForgotPasswordInterface}
          {...additionalProps}
        />
      )
    }
    case EntityTypeEnum.CustomerType: {
      return (
        <CustomerTypeNew
          entity={entity as CustomerTypeInterface}
          {...additionalProps}
        />
      )
    }
    case EntityTypeEnum.ContentBox: {
      return (
        <ContentBox
          entity={entity as ContentBoxInterface}
          {...additionalProps}
        />
      )
    }
    case EntityTypeEnum.PaymentMethod: {
      return (
        <PaymentMethod
          entity={entity as PaymentMethodInterface}
          {...additionalProps}
        />
      )
    }
    case EntityTypeEnum.Comments:
      return <div />
    case EntityTypeEnum.ShippingFees:
      return (
        <ShippingFees
          entity={entity as ShippingFeesInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.PaymentButton:
      return (
        <PaymentButtonNew
          entity={entity as PaymentButtonInterface}
          key={`entity-${id}`}
          {...additionalProps}
        />
      )
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.warn(`unknown type ${type} of entity id = ${id}`)
        return (
          <div>
            <i className="fas fa-bug" />
            <span> !!! INVALID ENTITY !!!</span>
          </div>
        )
      }

      return <div className="test" />
    }
  }
}
