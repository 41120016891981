import { pageTypes } from 'common/constants/pageTypes'
import toolBoxTypes from 'common/constants/toolBoxTypes'
import { groups } from 'client/components/core/Sidebar/components/Toolbox/toolboxGroups'
import { PageTypeEnum } from '../../common/enums/PageTypeEnum'
import LibraryElementEnum from '../enums/LibraryElementEnum'

const common = {
  [groups.text]: [
    LibraryElementEnum.LibraryText,
    LibraryElementEnum.LibraryHeadline,
    LibraryElementEnum.LibraryBulletList,
    LibraryElementEnum.LibraryContentBox,
  ],
  [groups.media]: [
    LibraryElementEnum.LibraryImage,
    LibraryElementEnum.LibraryVideo,
    LibraryElementEnum.LibraryAudio,
    LibraryElementEnum.LibraryCarousel,
  ],
  [groups.layout]: [
    LibraryElementEnum.LibraryColumn3,
    LibraryElementEnum.LibraryColumn4,
    LibraryElementEnum.LibraryColumn6,
    LibraryElementEnum.LibraryRow,
    LibraryElementEnum.LibrarySection,
  ],
  [groups.form]: [
    toolBoxTypes.TOOLBOX_FORM_INPUT,
    toolBoxTypes.TOOLBOX_BUTTON,
    LibraryElementEnum.LibraryCheckbox,
    LibraryElementEnum.LibraryOptInRecaptcha,
  ],
  [groups.social]: [
    LibraryElementEnum.LibraryFacebookComments,
    LibraryElementEnum.LibraryTwitterTweetButton,
    LibraryElementEnum.LibrarySurvey,
  ],
  [groups.other]: [
    LibraryElementEnum.LibraryCountdown,
    LibraryElementEnum.LibraryMenu,
    LibraryElementEnum.LibraryHorizontalLine,
    LibraryElementEnum.LibraryRawHTML,
    LibraryElementEnum.LibraryFaq,
  ],
}

const firstGroups = {
  [groups.text]: common[groups.text],
  [groups.layout]: common[groups.layout],
}

const lastGroups = {
  [groups.media]: common[groups.media],
  [groups.social]: common[groups.social],
  [groups.other]: common[groups.other],
}

export default {
  [PageTypeEnum.FunnelContactUs]: {
    ...firstGroups,
    [groups.form]: [
      ...common[groups.form],
      LibraryElementEnum.LibraryContactUs,
    ],
    [groups.media]: common[groups.media],
    [groups.social]: common[groups.social],
    [groups.other]: common[groups.other],
  },
  [pageTypes.offerPage]: {
    ...firstGroups,
    [groups.form]: common[groups.form],
    [groups.payment]: [
      LibraryElementEnum.LibraryPaymentButton,
      toolBoxTypes.TOOLBOX_ORDER_BUMP,
      LibraryElementEnum.LibraryPaymentMethod,
      LibraryElementEnum.LibraryCustomerType,
      LibraryElementEnum.LibraryProduct,
      LibraryElementEnum.LibraryOfferPrice,
      toolBoxTypes.TOOLBOX_AGREEMENT,
      toolBoxTypes.TOOLBOX_COUPON,
      toolBoxTypes.TOOLBOX_TWO_STEP_PAYMENT_FORM,
      LibraryElementEnum.LibraryShippingFees,
    ],
    ...lastGroups,
  },
  [pageTypes.offerInner]: {
    ...common,
  },
  [pageTypes.offerUpSellPage]: {
    ...firstGroups,
    [groups.form]: common[groups.form],
    [groups.payment]: [
      LibraryElementEnum.LibraryOfferPrice,
      LibraryElementEnum.LibraryProduct,
      LibraryElementEnum.LibraryUpsellAgreeButton,
      LibraryElementEnum.LibraryUpsellDisagreeButton,
      LibraryElementEnum.LibraryCheckbox,
    ],
    ...lastGroups,
  },
  [pageTypes.offerThankYouPage]: {
    ...firstGroups,
    [groups.payment]: [
      LibraryElementEnum.LibraryOrderSummary,
      LibraryElementEnum.LibraryCheckbox,
    ],
    [groups.form]: common[groups.form],
    ...lastGroups,
  },
  [pageTypes.inactiveFunnelPage]: {
    ...common,
  },
  [pageTypes.salesPage]: {
    ...firstGroups,
    [groups.form]: common[groups.form],
    [groups.payment]: [
      LibraryElementEnum.LibrarySalesRedirectionButton,
      LibraryElementEnum.LibraryProduct,
    ],
    ...lastGroups,
  },
  [pageTypes.customLogin]: {
    ...firstGroups,
    [groups.media]: common[groups.media],
    [groups.other]: [
      ...common[groups.other],
      LibraryElementEnum.LibraryCustomLogin,
    ],
  },
  [pageTypes.inline]: {
    ...common,
  },
  [pageTypes.popup]: {
    ...common,
  },
  [pageTypes.squeeze]: {
    ...common,
  },
  [PageTypeEnum.LinkInBio]: {
    ...common,
  },
  [pageTypes.courseIntroduction]: {
    ...common,
  },
  [pageTypes.lecture]: {
    ...firstGroups,
    [groups.form]: [toolBoxTypes.TOOLBOX_OPEN_URL_BUTTON],
    ...lastGroups,
    [groups.other]: [
      LibraryElementEnum.LibraryCountdown,
      LibraryElementEnum.LibraryMenu,
      LibraryElementEnum.LibraryHorizontalLine,
      LibraryElementEnum.LibraryRawHTML,
      LibraryElementEnum.LibraryFaq,
      LibraryElementEnum.LibraryContentTable,
    ],
    [groups.social]: [
      LibraryElementEnum.LibraryFacebookComments,
      LibraryElementEnum.LibraryTwitterTweetButton,
    ],
  },
  [pageTypes.webinarRegistration]: {
    ...firstGroups,
    [groups.webinar]: [
      LibraryElementEnum.LibraryWebinarRegistrationDate,
      toolBoxTypes.TOOLBOX_START_TIMER,
    ],
    [groups.form]: common[groups.form],
    ...lastGroups,
  },
  [pageTypes.webinarSession]: {
    ...firstGroups,
    [groups.form]: common[groups.form],
    [groups.webinar]: [
      LibraryElementEnum.LibraryWebinarCallToAction,
      LibraryElementEnum.LibraryWebinarSessionVideo,
    ],
    [groups.media]: [LibraryElementEnum.LibraryImage],
    [groups.social]: common[groups.social],
    [groups.other]: common[groups.other],
  },
  [pageTypes.webinarThankYou]: {
    ...firstGroups,
    [groups.form]: common[groups.form],
    [groups.webinar]: [
      LibraryElementEnum.LibraryWebinarSessionDateTime,
      LibraryElementEnum.LibraryWebinarSessionLink,
    ],
    ...lastGroups,
  },
  [pageTypes.optInThankYou]: {
    ...common,
  },
  [pageTypes.infoPage]: {
    [groups.text]: common[groups.text],
    [groups.media]: common[groups.media],
    [groups.form]: [toolBoxTypes.TOOLBOX_OPEN_URL_BUTTON],
    [groups.layout]: common[groups.layout],
  },
  [pageTypes.blogPostBody]: {
    ...firstGroups,
    [groups.blog]: [LibraryElementEnum.LibraryContentTable],
    [groups.form]: [
      toolBoxTypes.TOOLBOX_FORM_INPUT,
      toolBoxTypes.TOOLBOX_BLOG_BUTTON,
      LibraryElementEnum.LibraryCheckbox,
      LibraryElementEnum.LibraryOptInRecaptcha,
    ],
    ...lastGroups,
  },
  [pageTypes.blogPostLayout]: {
    ...firstGroups,
    [groups.blog]: [
      LibraryElementEnum.LibraryBlogPostBody,
      LibraryElementEnum.LibraryBlogPostTitle,
      LibraryElementEnum.LibraryBreadcrumbs,
      LibraryElementEnum.LibraryBlogPostImage,
      LibraryElementEnum.LibraryBlogPostDate,
      LibraryElementEnum.LibraryBlogPostCategories,
      LibraryElementEnum.LibraryBlogLatestPosts,
      LibraryElementEnum.LibraryLatestBlogPostsCarousel,
    ],
    [groups.form]: [
      toolBoxTypes.TOOLBOX_FORM_INPUT,
      toolBoxTypes.TOOLBOX_BLOG_BUTTON,
      LibraryElementEnum.LibraryCheckbox,
      LibraryElementEnum.LibraryOptInRecaptcha,
    ],
    ...lastGroups,
  },
  [pageTypes.blogStatic]: {
    ...firstGroups,
    [groups.form]: [
      toolBoxTypes.TOOLBOX_FORM_INPUT,
      toolBoxTypes.TOOLBOX_BLOG_BUTTON,
      LibraryElementEnum.LibraryCheckbox,
      LibraryElementEnum.LibraryContactUs,
      LibraryElementEnum.LibraryOptInRecaptcha,
    ],
    [groups.blog]: [
      LibraryElementEnum.LibraryBlogLatestPosts,
      LibraryElementEnum.LibraryBlogPostListing,
      LibraryElementEnum.LibraryBlogCategoryTitle,
      LibraryElementEnum.LibraryBlogCategoryDescription,
      LibraryElementEnum.LibraryLatestBlogPostsCarousel,
    ],
    ...lastGroups,
  },
  [pageTypes.blogHome]: {
    ...firstGroups,
    [groups.blog]: [
      LibraryElementEnum.LibraryBlogLatestPosts,
      LibraryElementEnum.LibraryBlogPostListing,
      LibraryElementEnum.LibraryLatestBlogPostsCarousel,
    ],
    [groups.form]: [
      toolBoxTypes.TOOLBOX_FORM_INPUT,
      toolBoxTypes.TOOLBOX_BLOG_BUTTON,
      LibraryElementEnum.LibraryCheckbox,
      LibraryElementEnum.LibraryOptInRecaptcha,
    ],
    ...lastGroups,
  },
  [pageTypes.blogLayout]: {
    ...firstGroups,
    [groups.blog]: [LibraryElementEnum.LibraryBlogContentPlaceholder],
    [groups.form]: [
      toolBoxTypes.TOOLBOX_FORM_INPUT,
      toolBoxTypes.TOOLBOX_BLOG_BUTTON,
      LibraryElementEnum.LibraryCheckbox,
      LibraryElementEnum.LibraryOptInRecaptcha,
    ],
    ...lastGroups,
  },
  [PageTypeEnum.NotFound]: {
    ...firstGroups,
    [groups.media]: common[groups.media],
    [groups.form]: [toolBoxTypes.TOOLBOX_OPEN_URL_BUTTON],
    [groups.other]: [
      LibraryElementEnum.LibraryMenu,
      LibraryElementEnum.LibraryHorizontalLine,
      LibraryElementEnum.LibraryRawHTML,
    ],
  },
}
